.codex-editor .ce-block__content,
.codex-editor .ce-toolbar__content {
  max-width: 700px;
  width: 87%;
}

.ce-toolbar__actions {
  right: 101%;
}

.codex-editor .ce-toolbar__content .ce-toolbar__plus {
  left: -34px;
}

.codex-editor .codex-editor__redactor {
  padding-bottom: 500px !important;
}

.cdx-block,
.cdx-unordred-list__input,
.cdx-ordered-list__input,
.ce-header {
  & span,
  & font,
  & b,
  & u,
  & a,
  & i {
    padding: 3px 0;
  }
}

.codex-editor .ce-block .ce-paragraph {
  word-break: keep-all;
  word-wrap: break-word;
}

.codex-editor .ce-block.ce-block--focused .image-block,
.codex-editor .ce-block.ce-block--focused .slt_wrapper {
  outline: 2px solid var(--blue7);
}

.codex-editor .ce-block.ce-block--focused .video-block video {
  outline: 2px solid var(--blue7);
}

.codex-editor .ce-block.ce-block--focused .ce-block__content .break-line .break-line__divider {
  border-top: 2px solid var(--blue7);
}

.codex-editor .ce-block.ce-block--focused .ce-block__content .ce-code .ce-code__textarea {
  outline: 2px solid var(--blue7);
}

.dummy-input {
  display: none;
}

.ce-popover-item:nth-child(4),
.ce-popover-item:nth-child(7),
.ce-popover-item:nth-child(10) {
  margin-bottom: 4px;
  position: relative;
}

.ce-popover-item:nth-child(4)::after,
.ce-popover-item:nth-child(7)::after,
.ce-popover-item:nth-child(10)::after {
  content: "";
  border-bottom: 1px solid var(--gray4);
  width: 100%;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.ce-popover-item:nth-child(5),
.ce-popover-item:nth-child(8),
.ce-popover-item:nth-child(11) {
  margin-top: 9px;
}
.ce-popover--opened {
  z-index: 9999;
}
.ce-popover--opened.ce-popover__custom-content.ce-popover__search,
.ce-popover__custom-content:not(:empty) {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--gray4);
}

.ce-inline-tool--bold,
.ce-inline-tool--italic,
.ce-inline-tool--underline,
.ce-inline-tool--marker,
.ce-inline-tool--inline-code {
  order: -1;
}

xy-color-picker {
  width: 100%;
}

.ce-conversion-toolbar__label {
  font-size: 1.1rem !important;
  padding: 8px 12px 4px;
}

.ce-conversion-tool {
  font-size: 1.3rem !important;
  color: var(--gary8);
}

.ce-conversion-tool:nth-child(4) {
  margin-bottom: 4px;
  position: relative;
}

.ce-conversion-tool:nth-child(4)::after {
  content: "";
  border-bottom: 1px solid var(--gray4);
  width: 100%;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.ce-conversion-tool:nth-child(5) {
  margin-top: 9px;
}

.ce-block a {
  color: var(--blue8);
}

.ce-block a:hover {
  color: var(--blue9);
}

.ce-block a:active {
  color: var(--blue7);
}
.tc-table {
  border-left: 1px solid var(--color-border);
}

@media print {
  @page {
    size: auto;
    margin: 5mm 5mm;
  }

  .codex-editor * {
    border: unset;
  }

  .codex-editor,
  .codex-editor.font-small {
    font-size: 1.8rem !important;
  }

  .codex-editor.font-medium {
    font-size: 2.5rem !important;
  }

  .codex-editor.font-large {
    font-size: 3.2rem !important;
  }

  .codex-editor__redactor {
    display: table; /* is like display flex but does not create page breaks inside images -> used to render table block correctly on print media*/
    width: 100%;
    padding: 0 10px;
    padding-bottom: 0 !important;
  }

  .ce-toolbar {
    display: none !important;
  }

  .ce-toolbar--hidden {
    display: none !important;
  }

  .image-block,
  .video-block {
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }
  .tc-table {
    border-left: 1px solid var(--color-border) !important;
  }
  .tc-add-column {
    display: none !important;
  }
  .tc-add-row {
    display: none !important;
  }
}
